<template>
  <div class="exchange">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">我要兑换</div>
    </div>
    <div class="top">
      <div class="title">请输入兑换码：</div>
      <van-field v-model="text" />
      <div class="btn" @click="search">查询</div>
    </div>
    <div class="bottom" v-if="showDetail">
      <div class="data_title">{{ detail.courseName }}</div>
      <div class="data_text">{{ detail.courseSecondName }}</div>
      <div class="data_price">¥{{ detail.coursePackagePrice }}</div>
      <div class="data_give">
        <span class="give">赠</span>
        <span
          v-for="(giveItem, index) in detail.giveCourseNames"
          :key="index"
          class="give_item"
          >{{ giveItem }}</span
        >
      </div>
      <div class="end">
        <span class="end_title">课程截止日期</span>
        <span class="end_text">{{ detail.coursePackageEfficientETime }}</span>
      </div>
      <div class="ash"></div>
      <div class="validity">
        <span> 兑换码有效期：</span>{{ detail.exchangeEfficientBTime }}～{{
          detail.exchangeEfficientETime
        }}
      </div>
      <div class="confirm" @click="confirm">确认兑换</div>
    </div>
  </div>
</template>


<script>
import { searchExchange, exchange } from "@/api/user.js";
export default {
  components: {},
  data() {
    return {
      text: "",
      showDetail: false,
      detail: {
        courseName: "注册会计师—考前刷题集训班",
        courseSecondName: "考点+好题、口诀+联想、重新认识初级会计考试",
        exchangeEfficientBTime: "2021-02-11",
        exchangeEfficientETime: "2021-02-22",
        giveCourseNames: ["高级财务课程 x1", "高级财务课程 x1"],
        coursePackageEfficientETime: "2022年5月31日",
      },
    };
  },
  created() {},
  methods: {
    gopath(val) {
      if (val == "back") {
        this.$router.go(-1);
      } else {
      }
    },
    search() {
      let formData = new FormData();
      formData.append("redemptionCode", this.text);
      searchExchange(formData).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data;
          this.showDetail = true;
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    confirm() {
      let formData = new FormData();
      formData.append("redemptionCode", this.text);
      exchange(formData).then((res) => {
        let that = this;
        if (res.data.code == 0) {
          this.$toast("兑换成功");
        } else {
          this.$toast(res.data.msg);
        }
        setTimeout(function () {
          that.showDetail = false;
        }, 2000);
      });
    },
    filterTime(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate();
      return `${Y}年${M}月${D}日`;
    },
  },
};
</script>

<style lang="less" scoped>
.exchange {
  height: 100vh;
  .header {
    padding: 10px 10px;
    display: flex;
    margin-bottom: 10px;
    .left {
      width: 10%;
      .icon {
        font-size: 24px;
        font-weight: 550;
        color: #141414;
      }
    }
    .title {
      width: 85%;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
    }
  }
  .top {
    background: #e5e6f7;
    padding: 20px 24px;
    .title {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #131313;
      line-height: 22px;
    }
    .van-cell {
      width: 100%;
      margin: 15px auto 19px;
      border-radius: 5px;
    }
    .btn {
      width: 50%;
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #5f7dff;
      border-radius: 5px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .bottom {
    padding: 24px 15px;
    .data_title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
      line-height: 23px;
    }
    .data_text {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin: 12px 0;
    }
    .data_price {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e85050;
      line-height: 25px;
      margin-bottom: 14px;
    }
    .data_give {
      padding: 13px 0;
      border: 1px solid #dddddd;
      border-left: none;
      border-right: none;
      display: flex;
      flex-wrap: wrap;
      .give {
        border: 2px solid #ff7200;
        padding: 3px;
        text-align: center;
        border-radius: 50%;
        height: 11px;
        line-height: 13px;
        width: 11px;
        color: #ff7200;
      }
      .give_item {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin: 0 10px 0 10px;
      }
    }
    .end {
      padding: 17px 0 14px 0;
      .end_title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3a3a3;
        line-height: 17px;
      }
      .end_text {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #424242;
        line-height: 18px;
        margin-left: 8px;
      }
    }
    .ash {
      position: relative;
      width: calc(100% + 30px);
      height: 6px;
      background: #f8f8f8;
      margin-left: -15px;
    }
    .validity {
      margin-top: 19px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 19px;
    }
    .confirm {
      position: relative;
      margin-top: 4vh;
      bottom: 10%;
      left: 15px;
      width: calc(100% - 30px);
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #5f7dff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 5px;
    }
  }
}
</style>


